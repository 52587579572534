<template>
  <div class="footer_wrapper" v-if="webList">
    <span>{{webList.projectName}}</span>
    <span>{{webList.companyName || ''}}提供技术支持</span>
    <span>客服：{{webList.customer}}</span>
    <span>售前QQ：{{webList.preSaleQq}}</span>
    <span>售后QQ：{{webList.afterSaleQq}}</span>
    <a class="hrefLine" href="https://beian.miit.gov.cn">{{webList.record}}</a>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
  export default {
    name: 'Footer',
    data() {
      return {
        data: '显示'
      }
    },
    created() {
      this.getWbeList()
    },
    computed: {
      ...mapGetters(['webList'])
    },
    methods: {
      ...mapActions(['getWbeList']),
    }
  }
</script>

<style lang="scss" scoped>
  .footer_wrapper {
    margin: 1.5vh;
    display: flex;
    font-size: 14px;
    justify-content: center;
    flex-wrap: wrap;

    & span,a{
      margin: 0.5vh;
    }
  }
</style>