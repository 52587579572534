<template>
  <div class="login__wrapper flex_con">
    <el-form :model="loginForm" :rules="loginrules" ref="loginForm">
      <el-form-item prop="phone">
        <el-input
          placeholder="请输入手机号"
          v-model="loginForm.phone">
        </el-input>
        <ch-icon name="login-user"></ch-icon>
      </el-form-item>

      <el-form-item prop="validate">
        <el-input
          placeholder="请输入验证码"
          auto-complete="new-password"
          autocomplete="new-password"
          v-model="loginForm.validate">
        </el-input>
        <ch-icon name="login-dun"></ch-icon>
        <div
          class="ch-code"
          @click="getCode"
          :key="key"
        >获取验证码</div>
      </el-form-item>

      <el-form-item prop="newPassword">
        <el-input
          type="password"
          placeholder="请输入密码"
          v-model="loginForm.newPassword"
          auto-complete="new-password"
          autocomplete="new-password"
          show-password>
        </el-input>
        <ch-icon name="login-pass"></ch-icon>
      </el-form-item>

      <el-form-item prop="confirmPassword">
        <el-input
          type="password"
          placeholder="请确认密码"
          v-model="loginForm.confirmPassword"
          auto-complete="new-password"
          autocomplete="new-password"
          show-password>
        </el-input>
        <ch-icon name="login-pass"></ch-icon>
      </el-form-item>

      <div class="login-text flex_con">
        <span class="login" @click="handleChangePassword">前往登录</span>
      </div>

      <el-button
        :loading="loading"
        size="max" class="flex_con"
        @click="handleLogin('loginForm')"
      >修改并登录</el-button>
    </el-form>



  </div>
</template>

<script>
import {phone} from "@/utils/validator";

export default {
  props:["type"],

  data() {
    var validatePass = (rule, value, callback) => {
      if (value !== this.loginForm.newPassword) {
        callback(new Error('两次密码不相同'))
      } else {
        callback()
      }
    }
    return {
      loginForm: {
        confirmPassword: '', // 确认密码
        newPassword: '',
        phone: '',
        validate: '', // 验证码
      },
      isLogin: true,
      loading: false,
      key: 0,
      loginrules: {
        phone: [{ required: true, pattern: phone, message: "请输入正确手机号", trigger: ["blur", "change"] }],
        validate: [{ required: true, message: "请输入验证码", trigger: ["blur", "change"]  }],
        newPassword: [{ required: true, message: "请输入密码", trigger: ["blur", "change"]  }],
        confirmPassword: [
          { required: true, message: "请再次输入密码", trigger: ["blur", "change"]  },
          { validator: validatePass, trigger: ["blur", "change"] }
        ],
      }
    }
  },
  mounted() {
    let that = this
    document.onkeydown = function (e) {
      e = window.event || e
      //保证是在登录页面发出的enter事件
      if ((e.code === 'Enter' || e.code === 'enter')) {
        //调用登录函数
        that.handleLogin('loginForm');
      }
    }
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  methods: {
    // 切换登录
    handleChangePassword() {
      this.$emit('update:type', 1)
    },

    // 获取验证码
    getCode(){
      if(!phone.test(this.loginForm.phone)) {
        this.$message.warning("请确认手机号")
        this.key += 1
        return
      }
      this.$curl.get("/client/cli/validate/" + this.loginForm.phone)
        .then(() => {
          this.$message.success("验证码已发送")
        }).catch(() => {
          this.$message.error("手机号不存在")
        })
    },

    // 修改密码并登录
    handleLogin(refName) {
      let self = this;
      this.$refs[refName].validate((valid) => {
        if (valid) {
          this.loading = true;
            self.$curl.get("/client/cli/phoneUpdateLogin", self.loginForm)
              .then((res) => {
                if (!res.data.code) {
                  localStorage.setItem("token", "Bearer " + res.data.access_token)
                  localStorage.setItem("refresh_token", res.data.refresh_token)
                  localStorage.setItem("user_id", res.data.user_id)
                  this.$router.replace({ path: "/classicIndex" })
                } else if (res.data.code && res.data.code !== 200) {
                  this.$message.error(res.data.msg);
                  this.loading = false;
                }
              }
            ).catch(() => {
              this.loading = false;
            })
          }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login__wrapper {
  display: inline-block;
  overflow: hidden;
  ::v-deep .el-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & .el-form-item {
      position: relative;
      padding-bottom: 6px;
      .CH_icon {
        font-size: 16px;
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translate(-20%, -50%);
      }

      .ch-code {
        font-size: 12px;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translate(12%, -50%);
        color: #3D7FFF;
      }
    }

    & .el-input__icon {
      transform: translate(-6px, 2px);
    }

    & .el-input__inner {
      box-sizing: border-box;
      padding: 1px 30px 0 42px;
      width: 18vw;
      min-width: 260px;
      height: 5vh;
      color: #333;
      border-radius:200px;
      background-color: white;
      font-size: 15px;
      &::placeholder{
        transform: translateY(1px);
        font-size: 15px;
        color: #999999;
      }
    }

    & .el-form-item__error {
      margin: 2px 0 0 44px;
      min-width: 200px;
    }

    & .login-text {
      transform: translateY(0px);
      font-size: 14px;
      width: 16vw;

      .login {
        color: #3E7EFF;
        cursor: pointer;
      }

    }

    & .el-button {
      font-size: 15px;
      width: 18vw;
      min-width: 260px;
      height: 5vh;
      border-radius: 200px;
      background-color: #3E7EFFFF;
      color: #FFFFFF;
      margin-top: 2vh;
    }
  }
}
</style>


