// 微信号
const wechat = /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/
const wechatMsg = '请输入正确的微信号码'

// 手机号
const phone = /(^1[0-9][0-9]{9}$)|(^[5689][0-9]{7}$)|(^(852-)?[5689][0-9]{7}$)|(^0\d{2,3}[-.\s]?\d{7,8}$)/  // 香港手机号和大陆和大陆座机
const phoneMsg = '请输入正确的手机号'
const validPhone = (rule, value, callback) => {
  if (!phone.test(value) ) {
    callback(new Error('请输入正确的联系方式!'))
  } else if (value.length>11) {
    callback(new Error('请输入正确的11位联系方式!'))
  }else{
    callback()
  }
};
const validateValue = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入'))
  } else if(value <= 0) {
    callback(new Error('输入值要大于0'))
  } else {
    callback()
  }
}
// 座机
const telephone = /^0\d{2,3}-?\d{7,8}$/
const telephoneMsg = '请输入正确的座机号'

// 英文
const english =  /^.[A-Za-z]+$/
const englishMsg = '请输入英文字符'

// 银行卡号码
const bankCard = /^[1-9]\d{8,18}$/
const  bankCardMsg = '请输入正确的银行卡号码'
// 证件号码
const IDNumber = /^[a-z0-9A-Z]{0,50}$/
const IDNumberMsg =  '请输入正确的证件号码'
// 身份证号码,包括15位和18位的
const IDCard = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{7}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/
const IDCardMsg = '请输入正确的身份证号码'
// QQ号码
const qq = /^[1-9]\d{4,11}$/
const qqMsg = '请输入正确的QQ号码'
// 网址, 仅支持http和https开头的
const url = /^(http|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?$/
const urlMsg = '请输入以http和https开头的网址'
const USCC = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/

export {
  wechat, wechatMsg,
  validPhone,validateValue,
  english, englishMsg,
  telephone, telephoneMsg,
  phone, phoneMsg,
  bankCard, bankCardMsg,
  IDNumber, IDNumberMsg,
  IDCard, IDCardMsg,
  qq, qqMsg,
  url, urlMsg,USCC
}